import { AfterViewInit, Component, OnInit } from '@angular/core';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit,AfterViewInit{

  

  constructor(
    ){
    console.log(`Health check times--->${environment.deploymentTime}`)
  }

  ngAfterViewInit(): void {
    // To fix white screen issue
    document.getElementById('loading').style['display'] = 'none'
  }

  ngOnInit(): void {
      // // let  workflow = 'quote_details';
      // // this.router.navigateByUrl(`/${workflow}`)
      //  //Start watching for user inactivity.

      //  this.userIdle.startWatching();
 
      //  // Start watching when user idle is starting.
      //  this.userIdle.onTimerStart().subscribe((count) => {
      //    console.log(count)
      //  });
 
      //  // Start watch when time is up.
      //  this.userIdle.onTimeout().subscribe(() => {
      //    // console.log('Time is up!');
      //    sessionStorage.clear();
      //    this.userIdle.stopWatching();
      //    this.modalService.dismissAll();
      //    this.router.navigateByUrl('/login');
      //  });
  }
  title = 'salik-poc-ui';
  
}
