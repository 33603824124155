<div class="flex flex-col justify-between items-center h-screen ">
    <div class="w-full">
      <!-- <img
      class="liva-logo"
      src="assets/svg/liva-logo.svg"
      alt="liva-logo"
    /> -->
        <!-- <app-header [hideShareIcon]="true" [hideProfileIcon]="true"></app-header> -->
        <app-header-v2 class="w-full flex justify-center items-center " [hideWorkflow]="true"  ></app-header-v2>

    </div>
    <div class="flex-1 flex-col flex justify-center items-center w-full">
        <h1 class="text-3xl font-semibold my-8 ">Login</h1>
        <div class="w-full h-1 bg-[#FF6B00] max-w-96"></div>
        <form [formGroup]="loginForm" class="border  w-full p-4 bg-gray-100 max-w-96" (ngSubmit)="login()">
            <div class="flex flex-col  ">
                <!-- <p class="text-center text-sm text-black">Login To See Your Plan Status</p> -->

                <div class="flex flex-col gap-2" >
                    <label class="font-semibold" for="customerUniqueId">Customer Id</label>
                    <input class="h-8" name="customerUniqueId"    type="text" formControlName="customerUniqueId">
                    <div *ngIf="formControl['customerUniqueId'].touched && formControl['customerUniqueId'].invalid">
                        <div *ngIf="formControl['customerUniqueId'].errors['required']" class="text-[#FF6B00] text-sm">Custome Id is required</div>
                    </div>
                </div>
                <div class="flex flex-col gap-2">
                    <label class="font-semibold" for="password" >Password</label>
                    <div class="w-full relative">
                        <input class="h-8 w-full" name="password"  type=text  formControlName="password" id="" [ngClass]="{ 'is-invalid': submitted && formControl['password']['errors'],'hidepasswordText':!show }">
                        <img src="assets/icons/password-show.svg" (click)="show=!show" aria-hidden="true" *ngIf="show" class="absolute top-1 right-0">
                        <img src="assets/icons/password-hide.svg"  (click)="show=!show" aria-hidden="true" *ngIf="!show" class="absolute top-1 right-0">
                    </div>
                    <div *ngIf="formControl['password'].touched && formControl['password'].invalid">
                        <div *ngIf="formControl['password'].errors['required']" class="text-[#FF6B00] text-sm">Password is required</div>
                        <div *ngIf="formControl['password'].errors['minlength']" class="text-[#FF6B00] text-sm">Password Must be 8 character long</div>
                    </div>
                </div>
                <div class="flex justify-end mt-2 mb-1">
                    <button class="text-sm text-[#FF6B00] underline" type="button" [routerLink]="['/','forgot-password']" >Forgot password ?</button>
                    <!-- <button class="text-sm text-[#FF6B00] underline" type="button" [routerLink]="['/','kyc']">Reset Email ?</button> -->
                </div>
            </div>
            <!-- Captcha generation -->
            <div *ngIf="validateCaptch">
                <p class="mt-3">Please enter the code displayed below:</p>

                <span><input class="captcha-input" type="text" formControlName="captchaText" [ngClass]="{ 'is-invalid': submitted && formControl['captchaText'].errors}"></span>
                <div *ngIf="submitted && formControl['captchaText'].errors" class="invalid-feedback">
                    <div class="invalid-feedback d-block" *ngIf="formControl['captchaText']['errors']['required']">
                    Captcha is required</div>
                </div>
                <span class="captch-image">{{ captchaCode }}</span>
                </div>
            <div class="text-center my-4">
                <button class="bg-[#FF6B00] text-center rounded-full  text-white p-2 w-full  font-semibold text-xl" type="submit">LogIn</button>
            </div>
        </form>
    </div>
    <div class="w-full">
        <!-- <app-footer></app-footer> -->
        <app-footer-v2 class="w-full"></app-footer-v2>
    </div>
</div>
