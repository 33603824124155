<div class="w-full max-w-[896px] md:mb-4 " *ngIf="!showThisNavbar">
  <nav class="flex justify-center items-center mb-6 mt-12 relative  w-full  md:m-0 ">
    <div *ngIf="backButton" class="absolute left-4 md:hidden ">
      <img src="assets/svg/back-v2.svg" alt="" (click)="redirecToPreviousPage()">
    </div>
    
        <img *ngIf="!hideWorkflow" class=" h-12 md:h-[4.375rem] md:absolute md:left-0 md:top-12" src="assets/svg/liva-logo.svg" alt="" />
        <img *ngIf="hideWorkflow" class=" h-12 md:h-[4.375rem]  md:mt-12 " src="assets/svg/liva-logo.svg" alt="" />
        <div class="absolute right-0 md:top-20  ">
          <div class="" (click)="$event.stopImmediatePropagation()" >
            <img *ngIf="showProfileIcon"  class="share mr-4 cursor-pointer" src="/assets/svg/user1.svg" alt="user"  (click)="changeProfileDropdownStatus()" />
          </div>
          <div *ngIf="profileDropdownStatus" (click)="$event.stopImmediatePropagation()" class="bg-white border  shadow  text-[#FF6B00] rounded-lg min-w-40  p-3 absolute top-9 right-4 flex justify-center items-center md:z-20 md:min-w-48">
            <h1 class="text-center text-sm w-full cursor-pointer md:text-lg" (click)="redirectToResetPassword()">Change Password</h1>
           </div>
        </div>
      </nav>
      <div *ngIf="!hideWorkflow"  class=" hidden md:flex md:justify-between md:w-full md:mt-44 md:relative " [ngClass]="loading===1 ?'':'mb-10'">
        <div class="flex flex-col items-center" *ngFor="let routes of loadingChecker">
        <div class="h-14 w-14 flex justify-center items-center rounded-full   text-[1.375rem]  z-10" [ngClass]="loading >= routes.id ? 'text-white bg-[#FF6900]':'bg-white text-[#999999] border'">{{routes['id']}}</div>
        <h3 class=" text-center min-w-14" [ngClass]="loading >= routes.id ?'text-black font-bold':'text-[#666666]'">{{routes.title}}</h3>    
        </div>
        <div  class="border-2 rounded-l-sm border-[#E5E5E5]  absolute top-7 left-1 w-[95%]"></div>   
        <div *ngIf="loading == 1"  class="border-2 rounded-l-sm border-[#FF6900]  absolute top-7 left-1 w-[15%]"></div>   
        <div *ngIf="loading == 2" class="border-2 rounded-l-sm border-[#FF6900]  absolute top-7 left-1 w-[37%]"></div>   
        <div *ngIf="loading == 3" class="border-2 rounded-l-sm border-[#FF6900]  absolute top-7 left-1 w-[59%]"></div>   
        <div *ngIf="loading == 4" class="border-2 rounded-l-sm border-[#FF6900]  absolute top-7 left-1 w-[85%]"></div>   
        <div *ngIf="loading == 5" class="border-2 rounded-l-sm border-[#FF6900]  absolute top-7 left-1 w-[95%]"></div>   
      </div>
</div>

<div *ngIf="showThisNavbar">
  <!-- <app-header-v2></app-header-v2> -->
  <nav class="flex justify-center items-center mb-6 mt-14 relative  w-full">
    <div *ngIf="backButton" class="absolute left-4 md:left-12 md:mb-14  ">
      <img src="assets/svg/back-v2.svg" alt="" (click)="redirecToPreviousPage()">
    </div>
        <img class=" h-12" src="assets/svg/liva-logo.svg" alt="" />
      </nav>
</div>
