<div class=" flex flex-col justify-between h-screen">
    <div>
        <app-header-v2 [backButton]="true" [pageName] = '"error-page"' class="px-8"></app-header-v2>
    </div>
    <div class="flex flex-col items-center justify-center p-4 ">
        <!-- <img src="/assets/svg/sad-face-icon.svg" alt="Sad Emoticon" class="h-28 mb-4"> -->
        <!-- <h1 class="text-4xl font-bold ">404 </h1> -->
        <!-- <h3 class="text-md font-medium">Page Not Found !</h3> -->
        <p class="text-center md:text-[1.625rem]">We regret to inform you that we are unable to process your request at this time. Please try again later.</p>
         <button 
            *ngIf="showRetryButton" 
            class="mt-4 w-full bg-[#FF6900] cursor-pointer text-white text-xl font-bold h-12 rounded-full md:max-w-[18rem] md:block md:mx-auto disabled:opacity-50 disabled:cursor-not-allowed"
            (click)="retryCreateQuote()"
            [disabled]="isButtonDisabled"
            >   
            Retry
        </button>
        <p class="mt-2" *ngIf="showRetryButton && retryRemainingTime !== 0" >You can retry in {{retryRemainingTime}} seconds</p>
    </div>
    <div>
        <app-footer-v2 class="w-full"></app-footer-v2>
    </div>
</div>