<div class="flex flex-col justify-between items-center h-screen px-4">
    <div class="w-full">
        <!-- <app-header-v2 [hideWorkflow]="true" ></app-header-v2> -->
        <!-- <app-header-v2  [pageName]="'login-page'"></app-header-v2> -->
        <app-header-v2 class="w-full flex justify-center items-center " [hideWorkflow]="true"  ></app-header-v2>

    </div>
    <div class="flex-1 flex-col flex justify-center items-center w-full">
        <div class="flex justify-between w-full max-w-96">
            <button type="button" (click)="goBack()"> 
                <img  src="/assets/svg/back.svg" alt="back">
            </button>
            <h1 class="text-3xl font-semibold my-8 ">Change Password</h1>
            <div></div>
        </div>
        <div class="w-full h-1 bg-[#FF6B00] max-w-96"></div>
        <form [formGroup]="resetPasswordForm" class="border  w-full p-4 bg-gray-100 max-w-96" (ngSubmit)="resetPassword()">
            <div class="flex flex-col gap-4 ">
                <!-- <p class="text-center text-[#FF6B00] text-sm">
                    Please provide your registerd email to receive your LogIn credentials.</p> -->
                    <div class="flex flex-col gap-2">
                        <label class="font-semibold" for="password" >Old Password</label>
                        <div class="w-full relative">
                            <input class="h-8 w-full" name="password"  [type]="currentPasswordVisibility ? 'text':'password'" formControlName="password" >
                            <img src="/assets/svg/eye.svg" class="absolute right-1 top-1 z-10" alt="" *ngIf="currentPasswordVisibility" (click)="toggleCurrentPasswordVisibility()" >
                            <img src="/assets/svg/eye-off.svg" class="absolute right-1 top-1 z-10" alt="" *ngIf="!currentPasswordVisibility" (click)="toggleCurrentPasswordVisibility()">
                        </div>
                        <div *ngIf="formControl['password'].touched && formControl['password'].invalid">
                            <div *ngIf="formControl['password'].errors['required']" class="text-[#FF6B00] text-sm">Password is required</div>
                            <div *ngIf="formControl['password'].errors['minlength']" class="text-[#FF6B00] text-sm">Password Must be 8 character long</div>
                        </div>
                    </div>
                    <div class="flex flex-col gap-2">
                        <label class="font-semibold" for="newPassword" >New Password</label>
                        <div class="w-full relative">
                            <input class="h-8 w-full" name="newPassword"  [type]="newPasswordVisibility ? 'text' : 'password'" formControlName="newPassword" >
                            <img src="/assets/svg/eye.svg" class="absolute right-1 top-1 z-10" alt="" *ngIf="newPasswordVisibility" (click)="toggleNewPasswordVisibility()" >
                            <img src="/assets/svg/eye-off.svg" class="absolute right-1 top-1 z-10" alt="" *ngIf="!newPasswordVisibility" (click)="toggleNewPasswordVisibility()">
                        </div>
                        <div *ngIf="formControl['newPassword'].touched && formControl['newPassword'].invalid">
                            <div *ngIf="formControl['newPassword'].errors['required']" class="text-[#FF6B00] text-sm">newPassword is required</div>
                            <div *ngIf="formControl['newPassword'].errors['minlength']" class="text-[#FF6B00] text-sm">Password Must be 8 character long</div>
                        </div>
                    </div>
                    <div class="flex flex-col gap-2">
                        <label class="font-semibold" for="confirmPassword" >Confirm Password</label>
                        <div class="w-full relative">
                            <input class="h-8 w-full" name="confirmPassword"  [type]="confirmPasswordVisibility ? 'text' : 'password'" formControlName="confirmPassword" >
                            <img src="/assets/svg/eye.svg" class="absolute right-1 top-1 z-10" alt="" *ngIf="confirmPasswordVisibility" (click)="toggleConfirmPasswordVisibility()" >
                            <img src="/assets/svg/eye-off.svg" class="absolute right-1 top-1 z-10" alt="" *ngIf="!confirmPasswordVisibility" (click)="toggleConfirmPasswordVisibility()">
                        </div>
                        <div *ngIf="formControl['confirmPassword'].touched && formControl['confirmPassword'].invalid">
                            <div *ngIf="formControl['confirmPassword'].errors['required']" class="text-[#FF6B00] text-sm">confirmPassword is required</div>
                            <div *ngIf="formControl['confirmPassword'].errors['passwordMismatch']" class="text-[#FF6B00] text-sm">Passwords don't match</div>
                        </div>
                    </div>
            </div>
            <div class="text-center my-4">
                <button class="bg-[#FF6B00] text-center rounded-full  text-white p-2 w-full  font-semibold text-xl" [disabled]="resetPasswordForm.invalid" type="submit">Submit</button>
            </div>
        </form>
    </div>
    <div class="w-full">
        <app-footer-v2></app-footer-v2>
    </div>
</div>

