

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinner, NgxSpinnerComponent, NgxSpinnerService } from 'ngx-spinner';
import {CURRENTWORKFLOW, PREVIOUSWORKFLOW, QUOTEENQUIRYID, TOKEN} from 'src/app/shared/interface/master-interface';
import {flowBasedRouting} from 'src/app/shared/mock-data';
import { CommonService } from 'src/app/shared/services/common-service/common-service.service';
import { GenerateTokenService } from 'src/app/shared/services/generate-token/generate-token.service';
import { NavbarService } from 'src/app/shared/services/navbar.service';
import {PaymentService} from 'src/app/shared/services/payment/payment.service';
import {QuoteSummaryService} from 'src/app/shared/services/quote-summary/quote-summary.service';
import {SessionService} from 'src/app/shared/services/session-service/session.service';
import { CUSTOMERUUVID } from 'src/app/shared/session-variables';

@Component({
  selector: 'app-payment-success-v2',
  templateUrl: './payment-success-v2.component.html',
  styleUrls: ['./payment-success-v2.component.scss']
})

export class PaymentSuccessV2Component implements OnInit {

  currentPath:string
  quoteEnquiryId: number;
  showLoader: boolean = true
  previousWorkFlow: string;
  currentWorkflow: string;
  version: number;
  token: string;
  public premium:number;
  flowdata = flowBasedRouting;
  customerVehicleDetails:any

  workflowStatus = [
    {
      id: 1,
      path: 'confirm-details',
      text: 'Your Details'
    },
    {
      id: 2,
      path: 'quote-summary',
      text: 'Your Quote'
    },
    {
      id: 3,
      path: 'verify-order',
      text: 'Payment'
    },
    {
      id: 4,
      path: 'document-upload',
      text: 'Documents'
    }
  ];
  customerUuvid:any;

  constructor(
    private router : Router,
    private sessionStorage: SessionService,
    private paymentService:PaymentService,
    private activeRoute:ActivatedRoute,
    private quoteSumaryService: QuoteSummaryService,
    private spinner: NgxSpinnerService,
    private navbarService: NavbarService,
    private generateTokenService: GenerateTokenService,
    private commonService:CommonService

  ){

  }

  async ngOnInit(): Promise<void> {
    try {
      this.spinner.show();
      this.customerUuvid  = this.sessionStorage.get(CUSTOMERUUVID);
      if (this.sessionStorage?.get(TOKEN)) {
        this.token = this.sessionStorage?.get(TOKEN);
        await this.getCustomerLastWorkflow();
      } else {
        this.customerUuvid =this.activeRoute.snapshot.queryParamMap.get("customerUUvid");
      this.sessionStorage.set(CUSTOMERUUVID, this.customerUuvid );
        await this.generateToken();

      }
      this.quoteEnquiryId = this.sessionStorage.get(QUOTEENQUIRYID);
        this.token = this.sessionStorage.get(TOKEN);
        this.customerUuvid  = this.sessionStorage.get(CUSTOMERUUVID);
        this.previousWorkFlow = this.sessionStorage.get(PREVIOUSWORKFLOW);
        this.currentWorkflow = this.sessionStorage.get(CURRENTWORKFLOW);
        this.currentPath = this.router.url.split('/')[1].split('?')[0]
        this.customerVehicleDetails = await this.navbarService.getCustomerVehicleDetailsForQuotes()
      await this.verifyOrder();

    } catch (error) {

    }
    finally{
      this.spinner.hide()
    }

  }

  async verifyOrder()
  {
   try {
     const orderId=this.activeRoute.snapshot.queryParamMap.get("orderId");
     let params = {
      orderId:orderId,
      quoteEnquiryId:this.quoteEnquiryId,
      currentWorkFlow:this.currentWorkflow
     }
     let response = await this.paymentService.paymentStatus(params);
     console.log("REsponse",response.statusCode)
     if (response?.statusCode == 200)
      {
        this.premium=response["response"][0]["premium"]
        this.showLoader = false
      }
      else
      {
      let getFLows = await this.quoteSumaryService.getCustomerLastWorkflowInOurObject(this.flowdata);
      this.router.navigateByUrl(`${this.customerUuvid}/${getFLows[0].route}`)
      }

   } catch (error) {
    console.log("Error",error)
   }
   finally{
    // this.showLoader = false
  }
  }

  async redirectToUploadDocument()
  {
    try {
      this.spinner.show()
      let getFLows = await this.quoteSumaryService.getCustomerLastWorkflowInOurObject(this.flowdata);
      console.log("getFlows",getFLows)
      this.router.navigateByUrl(`${this.customerUuvid}/${getFLows[0].route}`)
    } catch (error) {

    }
    finally{
      this.spinner.hide()
    }
  }
  async getCustomerLastWorkflow() {
    try {
      let getFLows = await this.quoteSumaryService.getCustomerLastWorkflowInOurObject(this.flowdata);
      console.log("------------getFLows",getFLows);
      // return
      // if (results.response.length > 0) {
      //   this.workFlowObjects = results.response;
      //   let flows = this.flowdata.filter(flowObj => { return this.workFlowObjects[0].currentWorkflow.trim().toLowerCase() == flowObj.flowName });
      //   this.sessionStorage.set(QUOTEENQUIRYID, this.workFlowObjects[0].quoteEnquiryId);
      //   this.sessionStorage.set(VERSION, this.workFlowObjects[0].version);
      //   this.sessionStorage.set(PREVIOUSWORKFLOW, this.workFlowObjects[0].previousWorkflow);
      //   this.sessionStorage.set(CURRENTWORKFLOW, this.workFlowObjects[0].currentWorkflow);


        //  this.router.navigateByUrl(`${this.customerUuvid}/${getFLows[0].route}`);
      // }
    } catch (exception) {

    }
  }
  async generateToken() {
    try {
      let results = await this.generateTokenService.generateToken({ customerUuvid: this.customerUuvid });
      if (results) {
        this.sessionStorage.set(TOKEN, results.response.token);
        await this.getCustomerLastWorkflow();
      }
    } catch (exception) {

    }
  }
}
